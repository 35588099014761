import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { useAppSelector } from '../../../app/store';
import { formatDayjsDateWithoutTime } from '../../../common/utils/dateUtil';
import { Customer } from '../../orderLookup/orderLookupModels';

const NOT_FOUND_MSG = 'ORIGINAL OUTBOUND ORDER NOT FOUND';

export const OriginalOrderDisplay = ({ newRmaView = false }: { newRmaView?: boolean }) => {
    const rma = useAppSelector(state => state.rma.rma);

    const [accordianOpen, setAccordianOpen] = useState(newRmaView);
    const orderDetails = rma?.OriginalOrderDetails;
    const getNameString = (customer: Customer) => {
        var nameString = '';
        nameString += !!customer.FirstName ? `${customer.FirstName} ` : '';
        nameString += !!customer.LastName ? `${customer.LastName}` : '';
        return nameString;
    };

    const getAddressString = (customer: Customer) => {
        var addressString = '';
        addressString += !!getNameString(customer) ? `${getNameString(customer)}\n` : '';
        addressString += !!customer.Address1 ? `${customer.Address1}\n` : '';
        addressString += !!customer.Address2 ? `${customer.Address2}\n` : '';
        if (!!!addressString) {
            return '-';
        }
        addressString += `${customer.City}, ${customer.State} ${customer.ZipCode}\n`;
        addressString += customer?.Country?.Name || '';
        return addressString;
    };

    const originalOrdersNotFoundComponent = () => {
        if (newRmaView) {
            return (
                <Typography variant="body2" color="warning.main">
                    {NOT_FOUND_MSG}
                </Typography>
            );
        }

        return (
            <Button size="small" disabled>
                {NOT_FOUND_MSG}
            </Button>
        );
    };

    const columns: GridColDef[] = [
        {
            field: 'Description',
            headerName: 'Description',
            flex: 3,
        },
        {
            field: 'Item',
            headerName: 'SKU',
            flex: 2,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'QuantityShip',
            headerName: 'Qty Shipped',
            type: 'number',
            flex: 1.5,
        },
    ];

    if (!rma?.OriginalOrderDetails?.OrderData) {
        return (
            <Box sx={{ display: 'flex', pt: 0.5, flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                {originalOrdersNotFoundComponent()}
            </Box>
        );
    }

    return (
        <Accordion defaultExpanded={newRmaView}>
            <AccordionSummary
                aria-controls="unit-content"
                id="unit-header"
                onClick={() => {
                    setAccordianOpen(!accordianOpen);
                }}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Box sx={{ display: 'flex', p: 1, flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography color="primary.main" variant="button">
                        Original Outbound Order Found
                    </Typography>
                    <Button size="small">
                        Show <ExpandMoreIcon />
                    </Button>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ display: 'flex', px: 4, pb: 2, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 0.5 }}>
                        <Typography sx={{ mb: 0.5 }} variant="body2">
                            ORDER: {orderDetails?.OrderData.Order}
                        </Typography>
                        <Typography variant="body2">
                            Order Date: {formatDayjsDateWithoutTime(orderDetails?.OrderData.OrderDate)}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                        <DataGrid
                            getRowId={row => row.ItemId}
                            autoHeight
                            rows={orderDetails?.Items || []}
                            columns={columns}
                            disableRowSelectionOnClick
                            scrollbarSize={20}
                            hideFooter
                            getRowHeight={() => 'auto'}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, px: 0.5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography> SHIPPED TO:</Typography>
                            <Typography sx={{ whiteSpace: 'pre-wrap', ml: 2 }}>
                                {!!orderDetails?.Shipping
                                    ? getAddressString(orderDetails.Shipping)
                                    : 'Address Not Found'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};
